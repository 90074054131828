<template>
	<main>
		<section class="section hero-section">
			<div class="container">
				<div class="hero">
					<h1 class="hero__title">Mobile Application Development</h1>
				</div>
			</div>
		</section>
		<section class="section services-section">
			<div class="container">
				<div class="two-col-grid">
					<div class="col image-col">
						<div class="image">
							<img src="../../assets/images/Rectangle399.svg" alt="" />
						</div>
					</div>
					<div class="col content-col">
						<div class="content">
							<Subtitle>Mobile App Development</Subtitle>
							<h2 class="title">Crafting Innovative & Secure Mobile Experiences</h2>
							<p>
								In the mobile-first era, having a powerful and engaging mobile app is vital for businesses to stay competitive and reach
								their target audience effectively. At AV DEVS, we specialize in mobile app development, delivering cutting-edge solutions
								that cater to diverse platforms and meet the unique requirements of startups, enterprises, and high-traffic applications.
							</p>
							<br />
							<p>
								With expertise in iOS, Android, cross-platform, and native app development, we leverage the latest technologies to create
								fast, secure, and visually stunning mobile experiences.
							</p>
							<br />
							<p>
								<CustomButton class="custom-btn primary">
									<router-link to="/contact">
										get it touch with us
									</router-link>
								</CustomButton>
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section benefits-section">
			<div class="container">
				<div class="benefits-container">
					<div class="benefits-grid">
						<div class="grid-item" v-for="(item, i) in $store.state.siteData.mobile_application_development" :key="i">
							<CardAlt :index="item.id" :title="item.title" :text="item.text" :icon="item.icon" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section choose-us-section">
			<div class="container">
				<div class="content">
					<h3 class="title">
						Contact us today to discuss your Mobile Application Development needs and embark on a journey towards success.
					</h3>
				</div>
				<div class="get-in-touch">
					<CustomButton class="custom-btn primary">
						<router-link to="/contact">
							get it touch with us
						</router-link>
					</CustomButton>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapActions } from "vuex";
import Subtitle from "@/components/utils/Subtitle.vue";
import CustomButton from "@/components/utils/CustomButton.vue";
import CardAlt from "@/components/utils/CardAlt.vue";

export default {
	name: "EnterpriseSoftwareDevelopment",
	components: { Subtitle, CustomButton, CardAlt },
	metaInfo: {
		title: "Mobile Application Development | Enterprise Solutions",
		meta: [
			{
				name: "description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
			{
				name: "og:title",
				content: "The only technology partner you will ever need | Enterprise Solutions",
			},
			{
				name: "og:description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
		],
	},
	methods: {
		...mapActions(["fillTargets"]),
	},
	mounted() {
		this.fillTargets();
	},
};
</script>

<style lang="scss" scoped>
.hero-section {
	background: url("~@/assets/images/mobile_bg.svg") center/cover;
}

.title {
	margin-bottom: 0.5em;
}

.services-section {
	padding: 50px 0;
	background-color: $white;
	position: relative;
	isolation: isolate;

	&::after {
		content: "";
		background: #fff url("~@/assets/images/dotted.svg") no-repeat right bottom;
		background-size: contain;
		position: absolute;
		right: 0px;
		bottom: 150px;
		width: 100px;
		height: 200px;
		z-index: -1;
	}
	&::before {
		content: "";
		background: #fff url("~@/assets/images/frame.svg") no-repeat right bottom;
		background-size: contain;
		position: absolute;
		right: 60px;
		top: 150px;
		width: 50px;
		height: 50px;
		z-index: -1;
	}
}
.benefits-section {
	color: #3e4d5c;
	background: darken($white, 1%) url("~@/assets/images/rectangle.svg") center/contain;
	.benefits-container {
		text-align: center;
		padding-bottom: 3.2rem;

		.benefits-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 3vw;
			margin-top: 50px;

			@media screen and (max-width: 1600px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (max-width: 992px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (max-width: 576px) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
}

.choose-us-section {
	background-color: $secondary-color;
	padding: 4rem;
	.title {
		color: $white;
		font-size: 30px;
		font-weight: 600;
		text-transform: none !important;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.content {
		width: 60%;
	}

	.get-in-touch {
		width: 40%;
		justify-content: end;
		display: flex;
	}

	@media screen and (max-width: 992px) {
		padding-left: 0;
		padding-right: 0;

		.title {
			font-size: 24px;
		}
		.content {
			width: 100%;
			justify-content: center;
		}

		.get-in-touch {
			width: 100%;
			justify-content: center;
		}

		.container {
			flex-direction: column;
		}
	}

	@media screen and (max-width: 576px) {
		.content {
			width: 100%;
			justify-content: center;
		}

		.get-in-touch {
			width: 100%;
			justify-content: center;
		}

		.container {
			flex-direction: column;
		}
	}
}

.expert-section {
	padding-bottom: 120px;
}

.levels {
	svg {
		width: 100%;

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
	.level-content {
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.level-title {
		color: $primary-color;
		font-size: 1.875rem;
		font-weight: 700;
		margin-bottom: 0.5em;
	}
	.level-text {
		// font-size: 0.875rem;
		color: $text-dark;
	}

	.level {
		display: none;
		background-color: $white;
		box-shadow: 0 30px 40px rgb(226 230 233 / 25%);
		padding: 40px;
		max-width: 80%;
		margin: 0 auto;
		margin-bottom: 20px;

		@media screen and (max-width: 1200px) {
			display: block;
		}

		@media screen and (max-width: 768px) {
			max-width: none;
		}
	}
}
</style>
